import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import appConfigs from "rootSrc/app.config";
// import ogImage from "../../../images/ogimage.webp";

export default function Seo({pageTitle, pageDescription, pageKeywords}) {
    const metaDescription =
        pageDescription || appConfigs.metaData.SITE_DEFAULT_DESCRIPTION;
    const metaKeywords =
        pageKeywords || appConfigs.metaData.SITE_DEFAULT_KEYWORDS;
    const metaTitle = `${pageTitle} ${appConfigs.metaData.SITE_NAME}`;

    return (
        <Helmet
            title={pageTitle}
            titleTemplate={`%s  ${appConfigs.metaData.SITE_NAME}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                // {
                //   property: `og:image`,
                //   content: {ogImage},
                // },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    // source: http://www.html-5.com/metatags/format-detection-meta-tag.html
                    name: "format-detection",
                    content: "telephone=no",
                },
            ].concat(
                metaKeywords.length > 0
                    ? {
                        name: `keywords`,
                        content: metaKeywords.join(`, `),
                    }
                    : []
            )}
        />
    );
}

Seo.propTypes = {
    pageDescription: PropTypes.string,
    pageKeywords: PropTypes.arrayOf(PropTypes.string),
    pageTitle: PropTypes.string.isRequired,
};
