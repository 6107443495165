import React from 'react'
import Countdown from 'react-countdown'

const renderer = ({ days, hours, minutes, seconds }) => {
  const pad = (num) => num.toString().padStart(2, '0');

  return (
    <div className="countdown-timer">
      <div className="countdown-timer__day">
        <span className="number">{pad(days)}</span>
        <span className="text">Days</span>
      </div>
      <div className="countdown-timer__hour">
        <span className="number">{pad(hours)}</span>
        <span className="text">Hrs</span>
      </div>
      <div className="countdown-timer__min">
        <span className="number">{pad(minutes)}</span>
        <span className="text">Min</span>
      </div>
      <div className="countdown-timer__sec">
        <span className="number">{pad(seconds)}</span>
        <span className="text">Sec</span>
      </div>
    </div>
  )
}

function CountdownTimer({ date }) {

  return (
    <div>
      <Countdown
        date={date ?? new Date()} renderer={renderer}
        zeroPadTime={2}
      />
    </div>
  )
}

export default CountdownTimer