import { useEffect, useState, useRef } from "react";

export default function useWheelScroll({scrollUpto, delay = 150}) { // delay in milliseconds
  const [scrollY, setScrollY] = useState(0);
  const scrollLock = useRef(false); // useRef to keep track of scroll lock state

  const handleScroll = (deltaY) => {
    if (scrollLock.current) return; // Ignore scroll events if locked
    // setScrollY((prevScrollY) => Math.min(scrollUpto, Math.max(0, prevScrollY + deltaY)));
    setScrollY(window.scrollY)
    scrollLock.current = true; // Lock scrolling

    // Unlock scrolling after a delay
    setTimeout(() => {
      scrollLock.current = false;
    }, delay);
  };

  const handleWheel = (event) => {
    const deltaY = event.deltaY;
    if(window.scrollY===0){
      setScrollY(0)
    }
    handleScroll(deltaY);
  };

  const handleKeyDown = (event) => {
    const arrowKeys = ['ArrowUp', 'ArrowDown'];
    if (arrowKeys.includes(event.key)) {
      const deltaY = event.key === 'ArrowUp' ? -100 : 100;
      window.scrollTo(0,window.scrollY+deltaY)
      handleScroll(deltaY);
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleWheel);
    // document.addEventListener('wheel', handleWheel);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('scroll', handleWheel);
      // document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return { scrollY };
}
