import React, { useEffect, useRef, useState } from "react";
import Layout from "layout";
import Seo from "common/seo";
import innovationMolecule from "../images/home/marco-ha-molecule.png"
import microHaMolecule from "../images/home/micro-ha-molecule-img.png"
import antioxMolecule from "../images/home/antiox-molecule.png"
import immerseBg from "../images/home/immerse-bg.png"
import immerseHaMolecule from "../images/home/micro-ha-molecule--md.png"
import immerseAntioxMolecule from "../images/home/antiox-molecule-sm.png"
import mobileBanner from "../images/home/mobile-banner.png"
import mobileInveredMolecule from "../images/home/mobile-banner-macro-ha-molecule-inverted.png"
import mobileMacroSmallMolecule from "../images/home/mobile-micro-ha-molecule-small.png"
import mobileAntioxMolecule from "../images/home/mobile-antiox-molecule.png"
import mobileBlurredBubble from "../images/home/blurred-bubble-shape-mob.png"

import "./index.scss";
import CountdownTimer from "../components/common/countdown-timer/countdown-timer";
import useMobileAnimation from "../components/libs/hooks/useMobileAnimation";
import useWheelScroll from "../components/libs/hooks/useWheelScroll";
import SignUpForm from "../components/common/form";

const stages = [
    {
        scrollOffset: 0,
        scaleFrom: 1,
        opacity: [1, 0.3, 0.4],
        blur: [0, 10, 10],
        bgImage: "linear-gradient(to bottom, #006D9D, #EBF2F9)", // Dark to light gradient
    },
    {
        scrollOffset: 700,
        scaleFrom: 3,
        opacity: [0, 0.7, 1],
        blur: [0, 10, 0],
        bgImage: "linear-gradient(to bottom, #006D9D, #EBF2F9)", // Same gradient for consistency
    },
    {
        scrollOffset: 1400,
        scaleFrom: 3,
        opacity: [0, 1, 0],
        blur: [0, 0, 0],
        bgImage: "linear-gradient(to bottom, #006D9D, #EBF2F9)", // Same gradient
    },
    {
        scrollOffset: 2500,
        scaleFrom: 5,
        opacity: [0, 0, 0],
        blur: [0, 0, 0],
        bgImage: "linear-gradient(to bottom, #006D9D, #EBF2F9)", // Same gradient
    },
    {
        scrollOffset: 3000,
        scaleFrom: 9,
        opacity: [0, 0, 0],
        blur: [0, 0, 0],
        // No background image or gradient for the last stage
    },
];




const t = ['Our newest innovation', 'combines science', 'and elegance']

function HomePage() {
    const imgRefs = useRef([]);
    const textRefs = useRef([]);
    const [stageIndex, setStageIndex] = useState(0);
    const { scrollY } = useWheelScroll({ scrollUpto: stages[4].scrollOffset })

    const [text, setText] = useState({
        text: 'Our newest innovation',
        opacity: 1,
        color: '#14333F'
    });

    useMobileAnimation({ imgRefs, textRefs })

    const scale = 1 + scrollY / 400

    useEffect(() => {
        const handleBeforeUnload = () => {
            window.scrollTo(0, 0);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const currStageIndex = scrollY > stages[2].scrollOffset ? 2 : scrollY > stages[1].scrollOffset ? 1 : 0
        setStageIndex(currStageIndex)

    }, [scrollY])

    useEffect(() => {
        if (scrollY > 0)
            setText({
                ...text,
                opacity: 0,
            })
        setTimeout(() => {
            setText({
                text: t[stageIndex],
                opacity: 1,
                color: stageIndex === 0 ? '#14333F' : ''
            })
        }, 200)
    }, [stageIndex])

    const getOpacity = () => {

        const currStage = stages[stageIndex];
        const nextStage = stages[stageIndex + 1]

        const op = currStage.opacity.map((item, i) => {
            const isNegative = (nextStage.opacity[i] - item) < 0
            if (isNegative) {
                item -= (item - nextStage.opacity[i]) * (scrollY - currStage.scrollOffset) / 1000
            } else {
                item += (nextStage.opacity[i] - item) * (scrollY - currStage.scrollOffset) / 1000
            }
            return item

        })
        const bl = currStage.blur.map((item, i) => {
            const b = item - (item - nextStage.blur[i]) * (scrollY - currStage.scrollOffset) / 1000

            return b
        })

        return { op, bl }
    }

    const getScale = (stageIndex) => {
        const stage = stages[stageIndex];
        if (scale > stage.scaleFrom)
            if (stageIndex === 0)
                return 1 + scale - stage.scaleFrom;
            else
                return 1 + scale - stage.scaleFrom;
        return 1
    }

    const getBlockOpacity = (stageIndex) => {
        const stage = stages[stageIndex];
        if (stage.scrollOffset < (scrollY - (stages[stageIndex + 1].scrollOffset - stages[stageIndex].scrollOffset)))
            return 0;
        return 1
    }

    const getBgImage = () => {
        const stage = stages[stageIndex];
        return stage.bgImage;
    }

    return (
        <Layout pageClass="home">
            <Seo pageTitle="" pageDescription="" />

            <div className="desktop-animation"
                style={{
                    height: stages[4].scrollOffset + 800
                }}
            >
                <div className=""
                    style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 93
                    }}
                >
                    <section className="main-wrapper"
                        style={{
                            backgroundImage: stageIndex < stages.length - 1 ? getBgImage() : `url(${getBgImage()})`,
                            transition: '0.5s',
                            opacity: scrollY >= stages[3].scrollOffset ? 0 : 1,

                            // zIndex:50000
                        }}
                    >

                        {<div className="block-title" >
                            <h1
                                style={{
                                    transition: '1s',
                                    color: text.color,
                                    opacity: text.opacity,
                                    textAlign: 'center'
                                }}
                            >{text.text}</h1>
                        </div>}

                        <div className={`scroll-indicator ${scrollY > 50 ? 'fade-out' : ''}`}>
                            <div className="text">Scroll to start</div>
                            <div className="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="43" viewBox="0 0 12 43" fill="none">
                                    <path d="M5.46967 42.5303C5.76256 42.8232 6.23743 42.8232 6.53033 42.5303L11.3033 37.7574C11.5962 37.4645 11.5962 36.9896 11.3033 36.6967C11.0104 36.4038 10.5355 36.4038 10.2426 36.6967L6 40.9393L1.75736 36.6967C1.46446 36.4038 0.989591 36.4038 0.696698 36.6967C0.403804 36.9896 0.403804 37.4645 0.696697 37.7574L5.46967 42.5303ZM5.25 -3.27835e-08L5.25 42L6.75 42L6.75 3.27835e-08L5.25 -3.27835e-08Z" fill="#036E9E" />
                                </svg>
                            </div>
                        </div>

                        <div className="block-innovation"
                            style={{
                                transition: '2s',
                                transform: `scale(${getScale(0)})`,
                                opacity: getBlockOpacity(0),
                            }}
                        >
                            <img
                                src={innovationMolecule}
                                alt="Molecule Img"
                                className="molecule-media"
                                style={{
                                    transition: '2s',
                                    opacity: getOpacity().op[0]
                                }}
                            />
                        </div>

                        <div className="block-science"
                            style={{
                                transition: '2s',
                                transform: `scale(${getScale(3)})`,
                                transformOrigin:'60% 50%'
                            }}
                        >
                            <div className="molecule-shape molecule-shape__leftCenter">
                                <img
                                    src={antioxMolecule}
                                    alt="Molecule Shape Img"
                                    style={{
                                        filter: `blur(${getOpacity().bl[1]}px)`,
                                        // opacity: getOpacity().op[1],
                                        transition: '2s',
                                        transform: `scale(${getScale(0)})`,
                                        // opacity: getBlockOpacity(2),
                                    }}
                                ></img>
                            </div>

                        </div>

                        <div className="block-elegance"
                            style={{
                                transition: '2s',
                                transform: `scale(${getScale(2)})`,
                                transformOrigin:'45% 50%'
                            }}
                        >
                            <div className="molecule-shape molecule-shape__rightBottom">
                                <img src={microHaMolecule} alt="Molecule Shape Img"
                                    style={{
                                        transition: '2s',
                                        filter: `blur(${getOpacity().bl[2]}px)`,
                                        transform: `scale(${getScale(0)})`,
                                        // opacity: getBlockOpacity(1),
                                    }}
                                ></img>
                            </div>

                        </div>
                    </section>

                    <section className="block-immerse"
                        style={{
                            backgroundImage: `url(${immerseBg})`,
                            transition: '1s',
                            opacity: scrollY >= stages[3].scrollOffset ? 1 : 0
                        }}
                    >

                        <div className="molecule-shape molecule-shape__leftBottom">
                            <img src={immerseHaMolecule} width={344} height={404} alt="Molecule Shape Img"></img>
                        </div>

                        <div className="centered-content">
                            <div className="banner-content">
                                <h1>Immerse yourself</h1>
                                <p>Journal articles, clinical data, peer-to-peer presentations, samples, and more. Get the details first, <strong>sign up today</strong>!</p>

                                <div className="btn-wrap">
                                    <SignUpForm
                                        buttonTitle={'Sign up'}
                                        className="btn btn-primary"
                                    />
                                </div>

                                <div className="bottom-content">
                                    <p><strong>Celebrate the launch with us live at the 2024 AAD Annual Meeting, <span className="color-red">Booth 3139</span>.</strong></p>

                                    <CountdownTimer
                                        date="2024-03-08T00:00:00.000Z"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="molecule-shape molecule-shape__rightLeft">
                            <img src={immerseAntioxMolecule} width={164} height={167} alt="Molecule Shape Img"></img>
                        </div>
                    </section>
                </div>
            </div>
            <section className="block-mobile-banner" style={{
                backgroundImage: `url(${mobileBanner})`
            }}>

                <div className="molecule-shape molecule-shape__top">
                    <img
                        src={mobileInveredMolecule}
                        width={344}
                        height={404}
                        alt="Molecule Shape Img"

                    ></img>
                </div>

                <div className="centered-content" >
                    <div className="banner-content pt-200">
                        <h1 className="text-white">Our newest innovation</h1>

                        <div className="small-molecule">
                            <img
                                ref={(el) => (imgRefs.current[0] = el)}
                                style={{ transition: '0.1s' }}
                                src={mobileMacroSmallMolecule}
                                width={134}
                                height={135}
                                alt="Molecule Shape Img"
                            ></img>
                        </div>

                        <div className="block-title mb-190">
                            <h1
                                // ref={el => (textRefs.current[0] = el)}
                                style={{ transition: '0.1s' }}
                            >combines <br />science</h1>
                        </div>
                    </div>
                </div>

                <div className="molecule-shape molecule-shape__leftCenter">
                    <img
                        src={mobileAntioxMolecule}
                        alt="Molecule Shape Img"
                        ref={(el) => (imgRefs.current[1] = el)}
                        style={{ transition: '0.1s' }}
                    ></img>
                </div>

                <div className="centered-content" >
                    <div className="banner-content banner-content--alt">
                        <h1
                            style={{ transition: '0.1s' }}
                        // ref={el => (textRefs.current[1] = el)}
                        >and <br />elegance</h1>
                    </div>
                </div>

                <div className="molecule-shape molecule-shape__bottomRight">
                    <img
                        src={mobileBlurredBubble}
                        alt="Molecule Shape"
                    ></img>
                </div>

                <div className="centered-content">
                    <div className="banner-content immerse-block">
                        <h1>Immerse yourself</h1>
                        <p>Journal articles, clinical data, peer-to-peer presentations, samples, and more. Get the details first, <strong>sign up today</strong>!</p>

                        <div className="btn-wrap">
                            <SignUpForm
                                buttonTitle={'Sign up'}
                                className="btn btn-primary"
                            />
                        </div>

                        <div className="bottom-content">
                            <p><strong>Celebrate the launch with us live at the 2024 AAD Annual Meeting, <span className="color-red">Booth 3139</span>.</strong></p>

                            <CountdownTimer
                                date="2024-03-08T00:00:00.000Z"
                            />
                        </div>
                    </div>
                </div>
            </section >


        </Layout >
    );
}

export default HomePage;
