import { useLayoutEffect, useRef } from "react";

export default function useMobileAnimation({ imgRefs, textRefs }) {

  const preScroll = useRef(null);

  const elemDistanceBtm = (element) => window.innerHeight - element.getBoundingClientRect().bottom;
  const applyAnimation = (elem, isText) => {
    const distanceFromBottom = elemDistanceBtm(elem);
    const scrollPos = preScroll.current > window.scrollY;
    preScroll.current = window.scrollY;

    if (scrollPos && distanceFromBottom > window.innerHeight) {
      console.log('Element is above the fold');
      return;
    }

    if (distanceFromBottom < window.innerHeight) {
      let initialSclae = 1;
      if (isText) {
        initialSclae = 0.1
      }
      const scale = distanceFromBottom > 0 ? initialSclae + distanceFromBottom / 300 : initialSclae;
      elem.style.transform = `scale(${scale})`;
    }
  }

  const onScroll = () => {

    imgRefs.current.forEach((elem) => {
      applyAnimation(elem)

    });
    textRefs.current.forEach((elem) => {
      applyAnimation(elem, true)

    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
}